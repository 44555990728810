import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 287 421.017">
    <g transform="translate(-1091 -4337)">
      <g transform="translate(1091.596 4345.9)">
        <path
          d="M197.5,409.1V89.1l-1-1-7-4s-31,2-77,0v-28l-1-1-6-3s-105,3-105,19v299s-7,21.8,80,36C120.5,412.1,160.5,413.1,197.5,409.1Z"
          fill="#fff"
        />
        <g transform="translate(357.404 1250)">
          <rect
            width="1"
            height="13.8"
            transform="translate(-358 -1223.1)"
            fill="#707070"
          />
          <rect
            width="1"
            height="13.8"
            transform="translate(-160.6 -1223.1)"
            fill="#707070"
          />
          <rect
            width="197.9"
            height="1"
            transform="translate(-357.5 -1216.6)"
            fill="#707070"
          />
        </g>
        <g transform="translate(2300.207 1434.501) rotate(90)">
          <rect
            width="13.8"
            height="1.2"
            transform="translate(-1382.322 2067.51) rotate(90)"
            fill="#707070"
          />
          <rect
            width="13.8"
            height="1.2"
            transform="translate(-1022.384 2067.471) rotate(90)"
            fill="#707070"
          />
          <rect
            width="1"
            height="360.5"
            transform="translate(-1022.471 2073.958) rotate(90)"
            fill="#707070"
          />
        </g>
        <path d="M151.9,412.1C72.4,412.1,0,392.7,0,371.3a7.569,7.569,0,0,1,.1-1.5l-.1-.1V70.5a.576.576,0,0,1,.6-.6.645.645,0,0,1,.6.6V369.6l.2.2a7.719,7.719,0,0,0-.2,1.5c0,18.7,64.4,39.5,150.6,39.5,15.6,0,31-.6,45.7-1.7.4,0,.7.2.7.6s-.2.7-.6.7C182.9,411.6,167.5,412.1,151.9,412.1Z" />
        <path d="M151.9,90.7C77.2,90.7,0,83.1,0,70.5c0-4.7,10.1-8.8,30-12.4,18.9-3.4,45.7-5.9,75.5-7.1a.652.652,0,0,1,.1,1.3C37.1,55.1,1.3,63.6,1.3,70.5c0,9.1,60.5,18.9,150.6,18.9,15.5,0,30.9-.3,45.6-.9a.669.669,0,0,1,.7.6c0,.3-.3.7-.6.7C182.8,90.4,167.5,90.7,151.9,90.7Z" />
        <path d="M151.9,85.7C92.2,85.7,30.5,79.8,30.5,70c0-8.4,44.2-13.2,82.1-14.9a.652.652,0,0,1,.1,1.3c-53.2,2.3-81,8.5-81,13.6,0,7,48.3,14.4,120.2,14.4,12.9,0,25.5-.2,37.6-.7.3,0,.7.3.7.6a.669.669,0,0,1-.6.7C177.4,85.4,164.8,85.7,151.9,85.7Z" />
        <path d="M151.9,87.3C98.7,87.3,51.2,83,33.6,76.6c-6.8-2.5-7.7-5.8-7.7-5.9v-.2c0-3.9,8.3-7.3,24.5-10.1,15.4-2.7,31.6-4.7,56.2-5.8a.652.652,0,0,1,.1,1.3c-41.8,1.8-79.2,7.4-79.5,14.6.2.4,1.3,2.9,6.9,4.9C51.5,81.8,98.9,86,151.9,86c11,0,21.9-.2,32.4-.5.3,0,.7.3.7.6a.669.669,0,0,1-.6.7C173.8,87.1,162.9,87.3,151.9,87.3Z" />
        <path d="M151.9,88.6c-60.6,0-110.2-4-132.8-10.6-10.2-3-12.9-6.1-13-6.3a3.809,3.809,0,0,1-.6-1.9c0-10.2,50.7-16,97.6-17.9a.652.652,0,0,1,.1,1.3c-49.9,2-96.4,8.4-96.4,16.6a1.5,1.5,0,0,0,.4,1.1h0s2.6,2.9,12.3,5.8c22.4,6.6,71.9,10.6,132.4,10.6,11.4,0,22.7-.2,33.7-.5.3,0,.7.3.7.6a.669.669,0,0,1-.6.7C174.6,88.5,163.3,88.6,151.9,88.6Z" />
        <path d="M197.2,87.7l.8.8.2.4V400.3l-1.1-1.1-.2-.5V89.3l-.8-.8-.2-.4V87Z" />
        <path d="M196.5,88.6a.367.367,0,0,1-.3-.1l-7.4-3.9a.671.671,0,0,1,.6-1.2l7.4,3.9a.806.806,0,0,1,.3.9A.548.548,0,0,1,196.5,88.6Z" />
        <path d="M197.5,410.1a.576.576,0,0,1-.6-.6V398.9a.576.576,0,0,1,.6-.6.645.645,0,0,1,.6.6v10.6A.645.645,0,0,1,197.5,410.1Z" />
        <path d="M186.1,88.2a.367.367,0,0,1-.3-.1l-2-1.2a.652.652,0,0,1,.7-1.1l2,1.2a.651.651,0,0,1,.2.9A.864.864,0,0,1,186.1,88.2Z" />
        <path d="M112.7,84.7a.576.576,0,0,1-.6-.6v-28l-6.8-3.8a.652.652,0,0,1,.7-1.1l7.1,4,.3.6V84C113.3,84.4,113.1,84.7,112.7,84.7Z" />
        <path d="M53.9,259.5c-5,0-9-5.1-9-11.3s4.1-11.3,9-11.3c5,0,9,5.1,9,11.3S58.9,259.5,53.9,259.5Zm0-21.2c-4.3,0-7.8,4.5-7.8,10s3.5,10,7.8,10,7.8-4.5,7.8-10S58.2,238.3,53.9,238.3Z" />
        <path d="M53.9,259.5c-5,0-9-5.1-9-11.3,0-5.8,3.5-10.6,8.1-11.2a.908.908,0,0,1,.7.3.616.616,0,0,1-.1.7,11.2,11.2,0,0,0-2.8,7.7c0,5.5,3.5,10,7.8,10a2.2,2.2,0,0,0,.8-.1.908.908,0,0,1,.7.3.616.616,0,0,1-.1.7A8.037,8.037,0,0,1,53.9,259.5Zm-2.5-20.7c-3.1,1.4-5.2,5.1-5.2,9.4,0,5.5,3.5,10,7.8,10a5.991,5.991,0,0,0,3.6-1.2c-4.6-.5-8.2-5.4-8.2-11.2A12.992,12.992,0,0,1,51.4,238.8Z" />
        <path d="M53.9,291.3c-5,0-9-5.1-9-11.3s4.1-11.3,9-11.3c5,0,9,5.1,9,11.3S58.9,291.3,53.9,291.3Zm0-21.3c-4.3,0-7.8,4.5-7.8,10s3.5,10,7.8,10,7.8-4.5,7.8-10S58.2,270,53.9,270Z" />
        <path d="M53.9,291.3c-5,0-9-5.1-9-11.3,0-5.8,3.5-10.6,8.1-11.2a.908.908,0,0,1,.7.3.616.616,0,0,1-.1.7,11.2,11.2,0,0,0-2.8,7.7c0,5.5,3.5,10,7.8,10a2.2,2.2,0,0,0,.8-.1.908.908,0,0,1,.7.3.616.616,0,0,1-.1.7A8.391,8.391,0,0,1,53.9,291.3Zm-2.5-20.7c-3.1,1.4-5.2,5.1-5.2,9.4,0,5.5,3.5,10,7.8,10a5.991,5.991,0,0,0,3.6-1.2c-4.6-.5-8.2-5.4-8.2-11.2A12.746,12.746,0,0,1,51.4,270.6Z" />
        <path d="M53.9,322.4c-5,0-9-5.1-9-11.3s4.1-11.3,9-11.3c5,0,9,5.1,9,11.3S58.9,322.4,53.9,322.4Zm0-21.3c-4.3,0-7.8,4.5-7.8,10s3.5,10,7.8,10,7.8-4.5,7.8-10S58.2,301.1,53.9,301.1Z" />
        <path d="M53.9,322.4c-5,0-9-5.1-9-11.3,0-5.8,3.5-10.6,8.1-11.2a.908.908,0,0,1,.7.3.616.616,0,0,1-.1.7,11.2,11.2,0,0,0-2.8,7.7c0,5.5,3.5,10,7.8,10a2.2,2.2,0,0,0,.8-.1.908.908,0,0,1,.7.3.616.616,0,0,1-.1.7A8.391,8.391,0,0,1,53.9,322.4Zm-2.5-20.7c-3.1,1.4-5.2,5.1-5.2,9.4,0,5.5,3.5,10,7.8,10a5.991,5.991,0,0,0,3.6-1.2c-4.6-.5-8.2-5.4-8.2-11.2A12.507,12.507,0,0,1,51.4,301.7Z" />
        <path d="M53.9,353.7c-5,0-9-5.1-9-11.3s4.1-11.3,9-11.3c5,0,9,5.1,9,11.3S58.9,353.7,53.9,353.7Zm0-21.3c-4.3,0-7.8,4.5-7.8,10s3.5,10,7.8,10,7.8-4.5,7.8-10S58.2,332.4,53.9,332.4Z" />
        <path d="M53.9,353.7c-5,0-9-5.1-9-11.3,0-5.8,3.5-10.6,8.1-11.2a.908.908,0,0,1,.7.3.616.616,0,0,1-.1.7,11.2,11.2,0,0,0-2.8,7.7c0,5.5,3.5,10,7.8,10a2.2,2.2,0,0,0,.8-.1.908.908,0,0,1,.7.3.616.616,0,0,1-.1.7A8.391,8.391,0,0,1,53.9,353.7ZM51.4,333c-3.1,1.4-5.2,5.1-5.2,9.4,0,5.5,3.5,10,7.8,10a5.991,5.991,0,0,0,3.6-1.2c-4.6-.5-8.2-5.4-8.2-11.2A12.746,12.746,0,0,1,51.4,333Z" />
        <path d="M53.9,385.1c-5,0-9-5.1-9-11.3s4.1-11.3,9-11.3c5,0,9,5.1,9,11.3S58.9,385.1,53.9,385.1Zm0-21.2c-4.3,0-7.8,4.5-7.8,10s3.5,10,7.8,10,7.8-4.5,7.8-10S58.2,363.9,53.9,363.9Z" />
        <path d="M53.9,385.1c-5,0-9-5.1-9-11.3,0-5.8,3.5-10.6,8.1-11.2a.908.908,0,0,1,.7.3.616.616,0,0,1-.1.7,11.2,11.2,0,0,0-2.8,7.7c0,5.5,3.5,10,7.8,10a2.2,2.2,0,0,0,.8-.1.908.908,0,0,1,.7.3.616.616,0,0,1-.1.7A8.037,8.037,0,0,1,53.9,385.1Zm-2.5-20.7c-3.1,1.4-5.2,5.1-5.2,9.4,0,5.5,3.5,10,7.8,10a5.991,5.991,0,0,0,3.6-1.2c-4.6-.5-8.2-5.4-8.2-11.2A12.992,12.992,0,0,1,51.4,364.4Z" />
        <path d="M13.8,247.6c-3.3,0-5.8-4.6-5.8-10.4s2.6-10.4,5.8-10.4,5.8,4.6,5.8,10.4S17.1,247.6,13.8,247.6Zm0-19.6c-2.5,0-4.5,4.2-4.5,9.1s2.1,9.1,4.5,9.1,4.5-4.2,4.5-9.1C18.4,232.2,16.3,228,13.8,228Z" />
        <path d="M13.8,247.6c-3.3,0-5.8-4.6-5.8-10.4s2.6-10.4,5.8-10.4a3.292,3.292,0,0,1,1.9.6.54.54,0,0,1,.2.8,15.515,15.515,0,0,0-1.3,6.4c0,4,1.3,7.6,3.3,8.7a.54.54,0,0,1,.2.8C17.1,246.3,15.5,247.6,13.8,247.6Zm0-19.6c-2.5,0-4.5,4.2-4.5,9.1s2.1,9.1,4.5,9.1c1.1,0,2.1-.8,3-2.3-2-1.7-3.4-5.4-3.4-9.5a15.73,15.73,0,0,1,1.2-6.3C14.3,228.1,14.1,228,13.8,228Z" />
        <path d="M13.8,278.2c-3.3,0-5.8-4.6-5.8-10.4s2.6-10.4,5.8-10.4,5.8,4.6,5.8,10.4C19.7,273.7,17.1,278.2,13.8,278.2Zm0-19.5c-2.5,0-4.5,4.2-4.5,9.1s2.1,9.1,4.5,9.1,4.5-4.2,4.5-9.1C18.4,262.9,16.3,258.7,13.8,258.7Z" />
        <path d="M13.8,278.2c-3.3,0-5.8-4.6-5.8-10.4s2.6-10.4,5.8-10.4a3.292,3.292,0,0,1,1.9.6.54.54,0,0,1,.2.8,15.515,15.515,0,0,0-1.3,6.4c0,4,1.3,7.6,3.3,8.7a.54.54,0,0,1,.2.8C17.1,277,15.5,278.2,13.8,278.2Zm0-19.5c-2.5,0-4.5,4.2-4.5,9.1s2.1,9.1,4.5,9.1c1.1,0,2.1-.8,3-2.3-2-1.7-3.4-5.4-3.4-9.5a15.73,15.73,0,0,1,1.2-6.3A2.2,2.2,0,0,0,13.8,258.7Z" />
        <path d="M13.8,309.6c-3.3,0-5.8-4.6-5.8-10.4s2.6-10.4,5.8-10.4,5.8,4.6,5.8,10.4S17.1,309.6,13.8,309.6Zm0-19.5c-2.5,0-4.5,4.2-4.5,9.1s2.1,9.1,4.5,9.1,4.5-4.2,4.5-9.1S16.3,290.1,13.8,290.1Z" />
        <path d="M13.8,309.6c-3.3,0-5.8-4.6-5.8-10.4s2.6-10.4,5.8-10.4a3.292,3.292,0,0,1,1.9.6.54.54,0,0,1,.2.8,15.515,15.515,0,0,0-1.3,6.4c0,4,1.3,7.6,3.3,8.7a.54.54,0,0,1,.2.8C17.1,308.4,15.5,309.6,13.8,309.6Zm0-19.5c-2.5,0-4.5,4.2-4.5,9.1s2.1,9.1,4.5,9.1c1.1,0,2.1-.8,3-2.3-2-1.7-3.4-5.4-3.4-9.5a15.73,15.73,0,0,1,1.2-6.3A2.2,2.2,0,0,0,13.8,290.1Z" />
        <path d="M13.8,338.8c-3.3,0-5.8-4.6-5.8-10.4S10.6,318,13.8,318s5.8,4.6,5.8,10.4S17.1,338.8,13.8,338.8Zm0-19.5c-2.5,0-4.5,4.2-4.5,9.1s2.1,9.1,4.5,9.1,4.5-4.2,4.5-9.1S16.3,319.3,13.8,319.3Z" />
        <path d="M13.8,338.8c-3.3,0-5.8-4.6-5.8-10.4S10.6,318,13.8,318a3.292,3.292,0,0,1,1.9.6.54.54,0,0,1,.2.8,15.515,15.515,0,0,0-1.3,6.4c0,4,1.3,7.6,3.3,8.7a.54.54,0,0,1,.2.8C17.1,337.6,15.5,338.8,13.8,338.8Zm0-19.5c-2.5,0-4.5,4.2-4.5,9.1s2.1,9.1,4.5,9.1c1.1,0,2.1-.8,3-2.3-2-1.7-3.4-5.4-3.4-9.5a15.73,15.73,0,0,1,1.2-6.3A2.2,2.2,0,0,0,13.8,319.3Z" />
        <path d="M13.8,369c-3.3,0-5.8-4.6-5.8-10.4s2.6-10.4,5.8-10.4,5.8,4.6,5.8,10.4S17.1,369,13.8,369Zm0-19.5c-2.5,0-4.5,4.2-4.5,9.1s2.1,9.1,4.5,9.1,4.5-4.2,4.5-9.1S16.3,349.5,13.8,349.5Z" />
        <path d="M13.8,369c-3.3,0-5.8-4.6-5.8-10.4s2.6-10.4,5.8-10.4a3.292,3.292,0,0,1,1.9.6.54.54,0,0,1,.2.8,15.515,15.515,0,0,0-1.3,6.4c0,4,1.3,7.6,3.3,8.7a.54.54,0,0,1,.2.8C17.1,367.8,15.5,369,13.8,369Zm0-19.5c-2.5,0-4.5,4.2-4.5,9.1s2.1,9.1,4.5,9.1c1.1,0,2.1-.8,3-2.3-2-1.7-3.4-5.4-3.4-9.5a15.73,15.73,0,0,1,1.2-6.3A2.2,2.2,0,0,0,13.8,349.5Z" />
        <path d="M178.6,265.7a11,11,0,1,1,11-11A11.032,11.032,0,0,1,178.6,265.7Zm0-20.7a9.7,9.7,0,1,0,9.7,9.7A9.709,9.709,0,0,0,178.6,245Z" />
        <path d="M178.6,265.7a10.931,10.931,0,0,1-5.6-1.5.67.67,0,0,1-.3-.8.569.569,0,0,1,.7-.4c.3,0,.7.1,1,.1a9.709,9.709,0,0,0,9.7-9.7,9.534,9.534,0,0,0-4.8-8.3.67.67,0,0,1-.3-.8.569.569,0,0,1,.7-.4,10.947,10.947,0,0,1,9.8,10.9A10.8,10.8,0,0,1,178.6,265.7Zm-2.2-1.6a8.329,8.329,0,0,0,2.2.3,9.685,9.685,0,0,0,3.8-18.6,10.946,10.946,0,0,1-6,18.3Z" />
        <path d="M170.2,261.3a.55.55,0,0,1-.5-.3,13.575,13.575,0,0,1-1.3-2.4.63.63,0,0,1,.3-.8,9.808,9.808,0,0,0,5.7-8.8,8.424,8.424,0,0,0-.7-3.5.63.63,0,0,1,.3-.8,9.108,9.108,0,0,1,3.1-.9.713.713,0,0,1,.6.3,11.2,11.2,0,0,1,2,6.3,10.6,10.6,0,0,1-9.5,10.9Zm-.3-2.5a7.46,7.46,0,0,0,.7,1.2,9.746,9.746,0,0,0,8.1-9.5,9.177,9.177,0,0,0-1.6-5.3,12.092,12.092,0,0,0-1.8.5,9.671,9.671,0,0,1,.5,3.4A11.5,11.5,0,0,1,169.9,258.8Z" />
        <path d="M178.6,297.6a11,11,0,1,1,11-11A11.032,11.032,0,0,1,178.6,297.6Zm0-20.7a9.7,9.7,0,1,0,9.7,9.7A9.709,9.709,0,0,0,178.6,276.9Z" />
        <path d="M178.6,297.6a10.931,10.931,0,0,1-5.6-1.5.67.67,0,0,1-.3-.8.569.569,0,0,1,.7-.4c.3,0,.7.1,1,.1a9.709,9.709,0,0,0,9.7-9.7,9.534,9.534,0,0,0-4.8-8.3.67.67,0,0,1-.3-.8.569.569,0,0,1,.7-.4,10.929,10.929,0,0,1-1.1,21.8Zm-2.2-1.6a8.329,8.329,0,0,0,2.2.3,9.685,9.685,0,0,0,3.8-18.6,10.946,10.946,0,0,1-6,18.3Z" />
        <path d="M170.2,293.2a.55.55,0,0,1-.5-.3,13.575,13.575,0,0,1-1.3-2.4.63.63,0,0,1,.3-.8,9.808,9.808,0,0,0,5.7-8.8,8.424,8.424,0,0,0-.7-3.5.63.63,0,0,1,.3-.8,9.108,9.108,0,0,1,3.1-.9.713.713,0,0,1,.6.3,11.2,11.2,0,0,1,2,6.3,10.67,10.67,0,0,1-9.5,10.9Zm-.3-2.5a7.46,7.46,0,0,0,.7,1.2,9.746,9.746,0,0,0,8.1-9.5,9.177,9.177,0,0,0-1.6-5.3,12.09,12.09,0,0,0-1.8.5,9.671,9.671,0,0,1,.5,3.4A11.5,11.5,0,0,1,169.9,290.7Z" />
        <path d="M178.6,329.2a11,11,0,1,1,11-11A11.032,11.032,0,0,1,178.6,329.2Zm0-20.6a9.7,9.7,0,1,0,9.7,9.7A9.709,9.709,0,0,0,178.6,308.6Z" />
        <path d="M178.6,329.2a10.931,10.931,0,0,1-5.6-1.5.67.67,0,0,1-.3-.8.569.569,0,0,1,.7-.4c.3,0,.7.1,1,.1a9.709,9.709,0,0,0,9.7-9.7,9.534,9.534,0,0,0-4.8-8.3.67.67,0,0,1-.3-.8.569.569,0,0,1,.7-.4,10.947,10.947,0,0,1,9.8,10.9A10.8,10.8,0,0,1,178.6,329.2Zm-2.2-1.5a8.329,8.329,0,0,0,2.2.3,9.685,9.685,0,0,0,3.8-18.6,10.946,10.946,0,0,1-6,18.3Z" />
        <path d="M170.2,324.9a.55.55,0,0,1-.5-.3,13.575,13.575,0,0,1-1.3-2.4.63.63,0,0,1,.3-.8,9.808,9.808,0,0,0,5.7-8.8,8.424,8.424,0,0,0-.7-3.5.63.63,0,0,1,.3-.8,9.108,9.108,0,0,1,3.1-.9.713.713,0,0,1,.6.3,11.2,11.2,0,0,1,2,6.3,10.67,10.67,0,0,1-9.5,10.9Zm-.3-2.6a7.46,7.46,0,0,0,.7,1.2,9.746,9.746,0,0,0,8.1-9.5,9.177,9.177,0,0,0-1.6-5.3,12.09,12.09,0,0,0-1.8.5,9.671,9.671,0,0,1,.5,3.4A11.372,11.372,0,0,1,169.9,322.3Z" />
        <path d="M178.6,360.9a11,11,0,1,1,11-11A11.032,11.032,0,0,1,178.6,360.9Zm0-20.6a9.7,9.7,0,1,0,9.7,9.7A9.709,9.709,0,0,0,178.6,340.3Z" />
        <path d="M178.6,360.9a10.931,10.931,0,0,1-5.6-1.5.67.67,0,0,1-.3-.8.569.569,0,0,1,.7-.4c.3,0,.7.1,1,.1a9.709,9.709,0,0,0,9.7-9.7,9.534,9.534,0,0,0-4.8-8.3.67.67,0,0,1-.3-.8.569.569,0,0,1,.7-.4,10.947,10.947,0,0,1,9.8,10.9A10.8,10.8,0,0,1,178.6,360.9Zm-2.2-1.5a8.329,8.329,0,0,0,2.2.3,9.685,9.685,0,0,0,3.8-18.6,10.946,10.946,0,0,1-6,18.3Z" />
        <path d="M170.2,356.6a.55.55,0,0,1-.5-.3,13.575,13.575,0,0,1-1.3-2.4.63.63,0,0,1,.3-.8,9.808,9.808,0,0,0,5.7-8.8,8.424,8.424,0,0,0-.7-3.5.63.63,0,0,1,.3-.8,9.108,9.108,0,0,1,3.1-.9.713.713,0,0,1,.6.3,11.2,11.2,0,0,1,2,6.3,10.67,10.67,0,0,1-9.5,10.9Zm-.3-2.6a7.46,7.46,0,0,0,.7,1.2,9.746,9.746,0,0,0,8.1-9.5,9.177,9.177,0,0,0-1.6-5.3,12.09,12.09,0,0,0-1.8.5,9.671,9.671,0,0,1,.5,3.4A11.372,11.372,0,0,1,169.9,354Z" />
        <path d="M178.6,393a11,11,0,1,1,11-11A11.032,11.032,0,0,1,178.6,393Zm0-20.6a9.7,9.7,0,1,0,9.7,9.7A9.709,9.709,0,0,0,178.6,372.4Z" />
        <path d="M178.6,393a10.931,10.931,0,0,1-5.6-1.5.67.67,0,0,1-.3-.8.569.569,0,0,1,.7-.4c.3,0,.7.1,1,.1a9.709,9.709,0,0,0,9.7-9.7,9.534,9.534,0,0,0-4.8-8.3.67.67,0,0,1-.3-.8.569.569,0,0,1,.7-.4,10.947,10.947,0,0,1,9.8,10.9A10.8,10.8,0,0,1,178.6,393Zm-2.2-1.5a8.329,8.329,0,0,0,2.2.3,9.685,9.685,0,0,0,3.8-18.6,10.946,10.946,0,0,1-6,18.3Z" />
        <path d="M170.2,388.7a.55.55,0,0,1-.5-.3,13.575,13.575,0,0,1-1.3-2.4.63.63,0,0,1,.3-.8,9.808,9.808,0,0,0,5.7-8.8,8.424,8.424,0,0,0-.7-3.5.63.63,0,0,1,.3-.8,9.108,9.108,0,0,1,3.1-.9.713.713,0,0,1,.6.3,11.2,11.2,0,0,1,2,6.3,10.67,10.67,0,0,1-9.5,10.9Zm-.3-2.6a7.46,7.46,0,0,0,.7,1.2,9.746,9.746,0,0,0,8.1-9.5,9.177,9.177,0,0,0-1.6-5.3,12.09,12.09,0,0,0-1.8.5,9.671,9.671,0,0,1,.5,3.4A11.372,11.372,0,0,1,169.9,386.1Z" />
        <path d="M111.8,265.1a11,11,0,1,1,11-11A11.032,11.032,0,0,1,111.8,265.1Zm0-20.6a9.7,9.7,0,1,0,9.7,9.7A9.709,9.709,0,0,0,111.8,244.5Z" />
        <path d="M111.8,265.1a11.237,11.237,0,0,1-5.9-1.7,10.661,10.661,0,0,1-4.8-6.9,11.015,11.015,0,0,1,7.2-12.8.67.67,0,0,1,.8.3.605.605,0,0,1-.1.8,9.8,9.8,0,0,0-2.9,9.2,9.7,9.7,0,0,0,11.5,7.4c.3-.1.7-.2,1-.3a.67.67,0,0,1,.8.3.605.605,0,0,1-.1.8,10.977,10.977,0,0,1-5.1,2.7A9.737,9.737,0,0,1,111.8,265.1Zm-5.6-18.8a9.6,9.6,0,0,0-3.8,10,10.182,10.182,0,0,0,4.2,6.1,9.444,9.444,0,0,0,7.3,1.3,17.232,17.232,0,0,0,2.1-.7,11.051,11.051,0,0,1-11.2-8.6A11.871,11.871,0,0,1,106.2,246.3Z" />
        <path d="M119.6,260.6a10.983,10.983,0,0,1-9.1-17.1.55.55,0,0,1,.5-.3,11.983,11.983,0,0,1,2.7.1c.2,0,.3.1.4.3a1.087,1.087,0,0,1,.1.5,9.245,9.245,0,0,0-.3,5.2h0a9.621,9.621,0,0,0,7.7,7.4c.2,0,.3.1.4.3a1.087,1.087,0,0,1,.1.5,9.65,9.65,0,0,1-1.2,2.5.55.55,0,0,1-.5.3A.912.912,0,0,1,119.6,260.6Zm-8.2-16.1a9.626,9.626,0,0,0,8.5,14.8c.2-.4.5-.8.7-1.3a10.927,10.927,0,0,1-8-8.3h0a11.423,11.423,0,0,1,.1-5.2Z" />
        <path d="M111.8,297.1a11,11,0,1,1,11-11A11.032,11.032,0,0,1,111.8,297.1Zm0-20.6a9.7,9.7,0,1,0,9.7,9.7A9.709,9.709,0,0,0,111.8,276.5Z" />
        <path d="M111.8,297.1a10.991,10.991,0,0,1-3.5-21.4.67.67,0,0,1,.8.3.605.605,0,0,1-.1.8,9.8,9.8,0,0,0-2.9,9.2,9.7,9.7,0,0,0,11.5,7.4c.3-.1.7-.2,1-.3a.67.67,0,0,1,.8.3.605.605,0,0,1-.1.8,10.977,10.977,0,0,1-5.1,2.7A9.737,9.737,0,0,1,111.8,297.1Zm-5.6-18.8a9.6,9.6,0,0,0-3.8,10,9.7,9.7,0,0,0,11.5,7.4,17.232,17.232,0,0,0,2.1-.7,11.051,11.051,0,0,1-11.2-8.6A11.971,11.971,0,0,1,106.2,278.3Z" />
        <path d="M119.6,292.6a10.983,10.983,0,0,1-9.1-17.1.55.55,0,0,1,.5-.3h.8a12.252,12.252,0,0,1,1.9.2c.2,0,.3.1.4.3a1.087,1.087,0,0,1,.1.5,9.245,9.245,0,0,0-.3,5.2,9.621,9.621,0,0,0,7.7,7.4c.2,0,.3.1.4.3a1.087,1.087,0,0,1,.1.5,9.65,9.65,0,0,1-1.2,2.5.55.55,0,0,1-.5.3A1.2,1.2,0,0,1,119.6,292.6Zm-8.2-16.1a9.626,9.626,0,0,0,8.5,14.8c.2-.4.5-.8.7-1.3a10.927,10.927,0,0,1-8-8.3h0a11.423,11.423,0,0,1,.1-5.2Z" />
        <path d="M111.8,329.2a11,11,0,1,1,11-11A11.032,11.032,0,0,1,111.8,329.2Zm0-20.6a9.7,9.7,0,1,0,9.7,9.7A9.709,9.709,0,0,0,111.8,308.6Z" />
        <path d="M111.8,329.2a10.991,10.991,0,0,1-3.5-21.4.67.67,0,0,1,.8.3.605.605,0,0,1-.1.8,9.8,9.8,0,0,0-2.9,9.2,9.7,9.7,0,0,0,11.5,7.4c.3-.1.7-.2,1-.3a.67.67,0,0,1,.8.3.605.605,0,0,1-.1.8,10.977,10.977,0,0,1-5.1,2.7A19.418,19.418,0,0,1,111.8,329.2Zm-5.6-18.8a9.6,9.6,0,0,0-3.8,10,9.7,9.7,0,0,0,11.5,7.4,17.232,17.232,0,0,0,2.1-.7,11.051,11.051,0,0,1-11.2-8.6A11.871,11.871,0,0,1,106.2,310.4Z" />
        <path d="M119.6,324.7a10.983,10.983,0,0,1-9.1-17.1.55.55,0,0,1,.5-.3h.8a12.252,12.252,0,0,1,1.9.2c.2,0,.3.1.4.3a1.087,1.087,0,0,1,.1.5,9.245,9.245,0,0,0-.3,5.2,9.621,9.621,0,0,0,7.7,7.4c.2,0,.3.1.4.3a1.087,1.087,0,0,1,.1.5,9.65,9.65,0,0,1-1.2,2.5.55.55,0,0,1-.5.3A1.2,1.2,0,0,1,119.6,324.7Zm-8.2-16.1a9.626,9.626,0,0,0,8.5,14.8c.2-.4.5-.8.7-1.3a10.927,10.927,0,0,1-8-8.3h0a11.423,11.423,0,0,1,.1-5.2Z" />
        <path d="M111.8,361.2a11,11,0,1,1,11-11A11.032,11.032,0,0,1,111.8,361.2Zm0-20.6a9.7,9.7,0,1,0,9.7,9.7A9.709,9.709,0,0,0,111.8,340.6Z" />
        <path d="M111.8,361.3a10.991,10.991,0,0,1-3.5-21.4.67.67,0,0,1,.8.3.605.605,0,0,1-.1.8,9.8,9.8,0,0,0-2.9,9.2,9.7,9.7,0,0,0,11.5,7.4c.3-.1.7-.2,1-.3a.67.67,0,0,1,.8.3.605.605,0,0,1-.1.8,10.977,10.977,0,0,1-5.1,2.7A19.412,19.412,0,0,1,111.8,361.3Zm-5.6-18.9a9.6,9.6,0,0,0-3.8,10,9.7,9.7,0,0,0,11.5,7.4,17.232,17.232,0,0,0,2.1-.7,11.051,11.051,0,0,1-11.2-8.6A11.971,11.971,0,0,1,106.2,342.4Z" />
        <path d="M119.6,356.7a10.983,10.983,0,0,1-9.1-17.1.55.55,0,0,1,.5-.3h.8a12.252,12.252,0,0,1,1.9.2c.2,0,.3.1.4.3a1.087,1.087,0,0,1,.1.5,9.245,9.245,0,0,0-.3,5.2h0a9.621,9.621,0,0,0,7.7,7.4c.2,0,.3.1.4.3a1.087,1.087,0,0,1,.1.5,9.65,9.65,0,0,1-1.2,2.5.55.55,0,0,1-.5.3A1.2,1.2,0,0,1,119.6,356.7Zm-8.2-16.1a9.626,9.626,0,0,0,8.5,14.8c.2-.4.5-.8.7-1.3a10.927,10.927,0,0,1-8-8.3h0a11.423,11.423,0,0,1,.1-5.2Z" />
        <path d="M111.8,392.4a11,11,0,1,1,11-11A11.032,11.032,0,0,1,111.8,392.4Zm0-20.6a9.7,9.7,0,1,0,9.7,9.7A9.709,9.709,0,0,0,111.8,371.8Z" />
        <path d="M111.8,392.4a10.991,10.991,0,0,1-3.5-21.4.67.67,0,0,1,.8.3.605.605,0,0,1-.1.8,9.8,9.8,0,0,0-2.9,9.2,9.7,9.7,0,0,0,11.5,7.4c.3-.1.7-.2,1-.3a.67.67,0,0,1,.8.3.605.605,0,0,1-.1.8,10.977,10.977,0,0,1-5.1,2.7A19.418,19.418,0,0,1,111.8,392.4Zm-5.6-18.8a9.6,9.6,0,0,0-3.8,10,9.7,9.7,0,0,0,11.5,7.4,17.232,17.232,0,0,0,2.1-.7,11.051,11.051,0,0,1-11.2-8.6A11.871,11.871,0,0,1,106.2,373.6Z" />
        <path d="M119.6,387.8a10.983,10.983,0,0,1-9.1-17.1.55.55,0,0,1,.5-.3h.8a12.252,12.252,0,0,1,1.9.2c.2,0,.3.1.4.3a1.087,1.087,0,0,1,.1.5,9.245,9.245,0,0,0-.3,5.2h0a9.621,9.621,0,0,0,7.7,7.4c.2,0,.3.1.4.3a1.087,1.087,0,0,1,.1.5,9.65,9.65,0,0,1-1.2,2.5.55.55,0,0,1-.5.3A1.2,1.2,0,0,1,119.6,387.8Zm-8.2-16a9.626,9.626,0,0,0,8.5,14.8c.2-.4.5-.8.7-1.3a10.927,10.927,0,0,1-8-8.3h0a11.423,11.423,0,0,1,.1-5.2A5.652,5.652,0,0,0,111.4,371.8Z" />
        <path d="M103.2,56.5a.576.576,0,0,1-.6-.6V52.5l1-.5,4,3.1a.64.64,0,0,1-.8,1l-2.9-2.3v2.1A.858.858,0,0,1,103.2,56.5Z" />
        <path d="M26.5,79.5a.576.576,0,0,1-.6-.6V70.5a.6.6,0,0,1,1.2,0v8.4C27.2,79.2,26.9,79.5,26.5,79.5Z" />
      </g>
      <text transform="translate(1185 4361)" fill="#1d1d1d">
        <tspan x="-22.008" y="0">
          35cm
        </tspan>
      </text>
      <text transform="translate(1355 4578)" fill="#1d1d1d">
        <tspan x="-22.008" y="0">
          35cm
        </tspan>
      </text>
    </g>
  </svg>
);
