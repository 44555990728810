import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 392 427.6">
    <g transform="translate(-542 -4337)">
      <g transform="translate(354.4 5588.9)">
        <rect
          width="1"
          height="13.8"
          transform="translate(187.6 -1223.1)"
          fill="#707070"
        />
        <rect width="1" height="13.8" transform="translate(490 -1223.1)" fill="#707070" />
        <rect
          width="302.9"
          height="1"
          transform="translate(188.1 -1216.6)"
          fill="#707070"
        />
      </g>
      <g transform="translate(2481.207 5790.401) rotate(90)">
        <rect
          width="13.8"
          height="1"
          transform="translate(-1374.396 1606.996) rotate(90)"
          fill="#707070"
        />
        <rect
          width="13.8"
          height="1"
          transform="translate(-1064.397 1606.996) rotate(90)"
          fill="#707070"
        />
        <rect
          width="1"
          height="310.5"
          transform="translate(-1064.447 1613.346) rotate(90)"
          fill="#707070"
        />
      </g>
      <g transform="translate(542 4338.9)">
        <path
          d="M.5,383.2V84.8s-.8-2.7,3.2-5,21-11.5,101.7-14.3l1.7.4s3.7,2.2,3.8,2.1.2-3.1.2-3.1l1-.6,5.5.6a1176.791,1176.791,0,0,1,121.7,3.3c64.5,5.3,63.4,14,63.9,15.5l-.1,1.6.1,298.6s5.1,21.8-74.3,36.1c0,0-13.7,2.2-21.1,2.4l-10.4-9.7v10.6S128.5,429,74.6,419-.6,396.9.5,383.2Z"
          fill="#fff"
        />
      </g>
      <g transform="translate(542 4338.9)">
        <path d="M208.1,423.1a.576.576,0,0,1-.6-.6c0-.4.2-.7.6-.7,26.3-2.5,50.5-7.9,68.2-15.1,16.7-6.8,26.2-14.8,26.2-21.9a7.719,7.719,0,0,0-.2-1.5l.2-.2V85.8a.576.576,0,0,1,.6-.6.645.645,0,0,1,.6.6V383.2l-.1.1a7.568,7.568,0,0,1,.1,1.5c.1,16.4-41.9,33.2-95.6,38.3Z" />
        <path d="M151.9,425.7C72.4,425.7,0,406.2,0,384.9a7.569,7.569,0,0,1,.1-1.5l-.1-.2V84a.576.576,0,0,1,.6-.6.645.645,0,0,1,.6.6V383.1l.2.2a7.719,7.719,0,0,0-.2,1.5c0,18.7,64.4,39.5,150.6,39.5,15.6,0,31-.6,45.7-1.7.4,0,.7.2.7.6s-.2.7-.6.7C183,425.1,167.6,425.7,151.9,425.7Z" />
        <path d="M207.6,102.8a.645.645,0,0,1-.6-.6.669.669,0,0,1,.6-.7c45.7-2.3,95-8.8,95-17.5,0-9.1-60.5-18.9-150.6-18.9-4.4,0-8.8,0-13.1-.1-9.1-.1-18.5-.2-27.1.1a.652.652,0,0,1-.1-1.3c8.5-.3,18-.2,27.1-.1,4.4,0,8.8.1,13.1.1,74.7,0,151.9,7.5,151.9,20.1,0,10.4-49.7,16.5-96.2,18.9Z" />
        <path d="M151.9,104.2C77.3,104.2,0,96.7,0,84c0-4.7,10.1-8.8,30-12.4,18.9-3.4,45.7-5.9,75.5-7.1a.652.652,0,1,1,.1,1.3C37.2,68.7,1.3,77.1,1.3,84c0,9.1,60.5,18.9,150.6,18.9,15.5,0,30.9-.3,45.6-.9a.669.669,0,0,1,.7.6c0,.3-.3.7-.6.7C182.8,103.9,167.5,104.2,151.9,104.2Z" />
        <path d="M197,98.1a.645.645,0,0,1-.6-.6.669.669,0,0,1,.6-.7c51.6-2.6,75.1-8.7,75.1-13.3,0-7-48.3-14.4-120.2-14.4-9.4,0-20.6.1-31.9.4-.3,0-.7-.3-.7-.6a.669.669,0,0,1,.6-.7c11.3-.4,22.5-.4,31.9-.4,59.7,0,121.4,5.9,121.4,15.7.2,7.9-39.2,12.7-76.2,14.6Z" />
        <path d="M151.9,99.2c-59.7,0-121.4-5.9-121.4-15.7,0-8.4,44.2-13.2,82.1-14.9a.652.652,0,1,1,.1,1.3c-53.1,2.3-80.9,8.6-80.9,13.6,0,7,48.3,14.4,120.2,14.4,12.9,0,25.5-.2,37.6-.7.3,0,.7.3.7.6a.669.669,0,0,1-.6.7C177.5,98.9,164.8,99.2,151.9,99.2Z" />
        <path d="M200.6,99.6a.645.645,0,0,1-.6-.6.669.669,0,0,1,.6-.7c36.7-2,76.1-7.3,76.1-14.3,0-1-1.1-2.2-3.2-3.3h0s-5.1-2.1-7.5-2.8c-21.2-5.7-67-9.5-114-9.5-11.4,0-22.1.3-32,.7a.652.652,0,0,1-.1-1.3c10-.3,20.7-.7,32.1-.7,47.1,0,93,3.8,114.3,9.5,2.5.7,7.5,2.8,7.7,2.9,2.7,1.4,4,2.9,4,4.4,0,8.5-40,13.7-77.4,15.7Z" />
        <path d="M151.9,100.9c-53.1,0-100.7-4.3-118.3-10.7-6.8-2.5-7.7-5.8-7.7-5.9v-.2c0-3.9,8.3-7.3,24.5-10.1,15.4-2.7,36.6-4.7,61.2-5.8a.652.652,0,1,1,.1,1.3C69.9,71.2,27.6,76.8,27.2,84c.2.4,1.3,2.9,6.9,4.9,17.5,6.4,64.8,10.7,117.8,10.7,11,0,21.9-.2,32.4-.5.3,0,.7.3.7.6s-.3.7-.6.7C173.9,100.7,162.9,100.9,151.9,100.9Z" />
        <path d="M204,101a.645.645,0,0,1-.6-.6.669.669,0,0,1,.6-.7c64-3,93.1-10.6,93.1-16.3,0-.9-.7-1.8-2-2.7h0a49.755,49.755,0,0,0-10-4.1C263.8,71.2,214.4,65.8,152,65.8c-15.6,0-26.9.2-37.7.5a.652.652,0,0,1-.1-1.3c10.9-.4,22.2-.5,37.8-.5,62.5,0,112,5.4,133.4,10.8a49.586,49.586,0,0,1,10.4,4.2c1.7,1.2,2.6,2.5,2.6,3.8C298.3,93,249.6,98.8,204,101Z" />
        <path d="M151.9,102.2c-60.6,0-110.2-4-132.8-10.6-10.2-3-12.9-6.1-13-6.3a3.809,3.809,0,0,1-.6-1.9c0-10.2,54.7-16,101.6-17.9a.669.669,0,0,1,.7.6c0,.3-.3.7-.6.7C57.4,68.8,6.8,75.1,6.8,83.4a1.5,1.5,0,0,0,.4,1.1h0s2.6,2.9,12.3,5.8c22.4,6.6,71.9,10.6,132.4,10.6,11.4,0,22.7-.2,33.7-.5.3,0,.7.3.7.6a.669.669,0,0,1-.6.7C174.7,102,163.3,102.2,151.9,102.2Z" />
        <path d="M207.5,422.9l-10.4-10.1-.2-.5V102.8l-.8-.8-.2-.4-.2-4.3.9-.6,11.4,5.2.4.6.1,320Zm-9.3-10.8,9.1,8.8-.1-318.1-10.1-4.6.1,3.1.8.8.2.4Z" />
        <path d="M196.6,102.2a.367.367,0,0,1-.3-.1l-7.4-3.9a.671.671,0,0,1,.6-1.2l7.4,3.9a.806.806,0,0,1,.3.9C197,102,196.8,102.2,196.6,102.2Z" />
        <path d="M197.5,423.6a.576.576,0,0,1-.6-.6V412.4a.576.576,0,0,1,.6-.6.645.645,0,0,1,.6.6V423C198.2,423.3,197.9,423.6,197.5,423.6Z" />
        <path d="M186.1,101.7a.367.367,0,0,1-.3-.1l-2-1.2a.652.652,0,0,1,.7-1.1l2,1.2a.651.651,0,0,1,.2.9A.864.864,0,0,1,186.1,101.7Z" />
        <path d="M112.7,98.2a.576.576,0,0,1-.6-.6v-28l-6.8-3.8a.652.652,0,0,1,.7-1.1l7.1,4,.3.6V97.6A.669.669,0,0,1,112.7,98.2Z" />
        <path d="M119.9,98.4a.576.576,0,0,1-.6-.6V69l-7.4-3.3v2.5a.6.6,0,0,1-1.2,0V64.7l.9-.6,8.7,3.9.4.6V97.8C120.5,98.1,120.2,98.4,119.9,98.4Z" />
        <path d="M54,273c-5,0-9-5.1-9-11.3s4.1-11.3,9-11.3c5,0,9,5.1,9,11.3C63,268,59,273,54,273Zm0-21.2c-4.3,0-7.8,4.5-7.8,10s3.5,10,7.8,10,7.8-4.5,7.8-10C61.7,256.3,58.3,251.8,54,251.8Z" />
        <path d="M54,273c-5,0-9-5.1-9-11.3,0-5.8,3.5-10.6,8.1-11.2a.908.908,0,0,1,.7.3.616.616,0,0,1-.1.7,11.2,11.2,0,0,0-2.8,7.7c0,5.5,3.5,10,7.8,10a2.2,2.2,0,0,0,.8-.1.908.908,0,0,1,.7.3.616.616,0,0,1-.1.7A8.35,8.35,0,0,1,54,273Zm-2.6-20.7c-3.1,1.4-5.2,5.1-5.2,9.4,0,5.5,3.5,10,7.8,10a5.991,5.991,0,0,0,3.6-1.2c-4.6-.5-8.2-5.4-8.2-11.2A12.992,12.992,0,0,1,51.4,252.3Z" />
        <path d="M54,304.8c-5,0-9-5.1-9-11.3s4.1-11.3,9-11.3c5,0,9,5.1,9,11.3S59,304.8,54,304.8Zm0-21.2c-4.3,0-7.8,4.5-7.8,10s3.5,10,7.8,10,7.8-4.5,7.8-10C61.7,288,58.3,283.6,54,283.6Z" />
        <path d="M54,304.8c-5,0-9-5.1-9-11.3,0-5.8,3.5-10.6,8.1-11.2a.908.908,0,0,1,.7.3.616.616,0,0,1-.1.7,11.2,11.2,0,0,0-2.8,7.7c0,5.5,3.5,10,7.8,10a2.2,2.2,0,0,0,.8-.1.908.908,0,0,1,.7.3.616.616,0,0,1-.1.7A8.35,8.35,0,0,1,54,304.8Zm-2.6-20.7c-3.1,1.4-5.2,5.1-5.2,9.4,0,5.5,3.5,10,7.8,10a5.991,5.991,0,0,0,3.6-1.2c-4.6-.5-8.2-5.4-8.2-11.2A12.992,12.992,0,0,1,51.4,284.1Z" />
        <path d="M54,335.9c-5,0-9-5.1-9-11.3s4.1-11.3,9-11.3c5,0,9,5.1,9,11.3S59,335.9,54,335.9Zm0-21.3c-4.3,0-7.8,4.5-7.8,10s3.5,10,7.8,10,7.8-4.5,7.8-10S58.3,314.6,54,314.6Z" />
        <path d="M54,335.9c-5,0-9-5.1-9-11.3,0-5.8,3.5-10.6,8.1-11.2a.908.908,0,0,1,.7.3.616.616,0,0,1-.1.7,11.2,11.2,0,0,0-2.8,7.7c0,5.5,3.5,10,7.8,10a2.2,2.2,0,0,0,.8-.1.908.908,0,0,1,.7.3.616.616,0,0,1-.1.7A8.739,8.739,0,0,1,54,335.9Zm-2.6-20.7c-3.1,1.4-5.2,5.1-5.2,9.4,0,5.5,3.5,10,7.8,10a5.991,5.991,0,0,0,3.6-1.2c-4.6-.5-8.2-5.4-8.2-11.2A12.746,12.746,0,0,1,51.4,315.2Z" />
        <path d="M54,367.2c-5,0-9-5.1-9-11.3s4.1-11.3,9-11.3c5,0,9,5.1,9,11.3S59,367.2,54,367.2Zm0-21.3c-4.3,0-7.8,4.5-7.8,10s3.5,10,7.8,10,7.8-4.5,7.8-10C61.7,350.4,58.3,345.9,54,345.9Z" />
        <path d="M54,367.2c-5,0-9-5.1-9-11.3,0-5.8,3.5-10.6,8.1-11.2a.908.908,0,0,1,.7.3.616.616,0,0,1-.1.7,11.2,11.2,0,0,0-2.8,7.7c0,5.5,3.5,10,7.8,10a2.2,2.2,0,0,0,.8-.1.908.908,0,0,1,.7.3.616.616,0,0,1-.1.7A8.739,8.739,0,0,1,54,367.2Zm-2.6-20.7c-3.1,1.4-5.2,5.1-5.2,9.4,0,5.5,3.5,10,7.8,10a5.991,5.991,0,0,0,3.6-1.2c-4.6-.5-8.2-5.4-8.2-11.2A12.746,12.746,0,0,1,51.4,346.5Z" />
        <path d="M54,398.7c-5,0-9-5.1-9-11.3s4.1-11.3,9-11.3c5,0,9,5.1,9,11.3S59,398.7,54,398.7Zm0-21.3c-4.3,0-7.8,4.5-7.8,10s3.5,10,7.8,10,7.8-4.5,7.8-10C61.7,381.9,58.3,377.4,54,377.4Z" />
        <path d="M54,398.7c-5,0-9-5.1-9-11.3,0-5.8,3.5-10.6,8.1-11.2a.908.908,0,0,1,.7.3.616.616,0,0,1-.1.7,11.2,11.2,0,0,0-2.8,7.7c0,5.5,3.5,10,7.8,10a2.2,2.2,0,0,0,.8-.1.908.908,0,0,1,.7.3.616.616,0,0,1-.1.7A8.739,8.739,0,0,1,54,398.7ZM51.4,378c-3.1,1.4-5.2,5.1-5.2,9.4,0,5.5,3.5,10,7.8,10a5.991,5.991,0,0,0,3.6-1.2c-4.6-.5-8.2-5.4-8.2-11.2A12.507,12.507,0,0,1,51.4,378Z" />
        <path d="M13.9,261.1c-3.3,0-5.8-4.6-5.8-10.4s2.6-10.4,5.8-10.4,5.8,4.6,5.8,10.4S17.1,261.1,13.9,261.1Zm0-19.5c-2.5,0-4.5,4.2-4.5,9.1s2.1,9.1,4.5,9.1,4.5-4.2,4.5-9.1C18.4,245.7,16.3,241.6,13.9,241.6Z" />
        <path d="M13.9,261.1c-3.3,0-5.8-4.6-5.8-10.4s2.6-10.4,5.8-10.4a3.292,3.292,0,0,1,1.9.6.54.54,0,0,1,.2.8,15.515,15.515,0,0,0-1.3,6.4c0,4,1.3,7.6,3.3,8.7a.54.54,0,0,1,.2.8C17.1,259.9,15.6,261.1,13.9,261.1Zm0-19.5c-2.5,0-4.5,4.2-4.5,9.1s2.1,9.1,4.5,9.1c1.1,0,2.1-.8,3-2.3-2-1.7-3.4-5.4-3.4-9.5a15.73,15.73,0,0,1,1.2-6.3A2.485,2.485,0,0,0,13.9,241.6Z" />
        <path d="M13.9,291.8c-3.3,0-5.8-4.6-5.8-10.4S10.7,271,13.9,271s5.8,4.6,5.8,10.4S17.1,291.8,13.9,291.8Zm0-19.6c-2.5,0-4.5,4.2-4.5,9.1s2.1,9.1,4.5,9.1,4.5-4.2,4.5-9.1S16.3,272.2,13.9,272.2Z" />
        <path d="M13.9,291.8c-3.3,0-5.8-4.6-5.8-10.4S10.7,271,13.9,271a3.292,3.292,0,0,1,1.9.6.54.54,0,0,1,.2.8,15.515,15.515,0,0,0-1.3,6.4c0,4,1.3,7.6,3.3,8.7a.54.54,0,0,1,.2.8C17.1,290.5,15.6,291.8,13.9,291.8Zm0-19.6c-2.5,0-4.5,4.2-4.5,9.1s2.1,9.1,4.5,9.1c1.1,0,2.1-.8,3-2.3-2-1.7-3.4-5.4-3.4-9.5a15.73,15.73,0,0,1,1.2-6.3C14.4,272.3,14.1,272.2,13.9,272.2Z" />
        <path d="M13.9,323.1c-3.3,0-5.8-4.6-5.8-10.4s2.6-10.4,5.8-10.4,5.8,4.6,5.8,10.4S17.1,323.1,13.9,323.1Zm0-19.5c-2.5,0-4.5,4.2-4.5,9.1s2.1,9.1,4.5,9.1,4.5-4.2,4.5-9.1S16.3,303.6,13.9,303.6Z" />
        <path d="M13.9,323.1c-3.3,0-5.8-4.6-5.8-10.4s2.6-10.4,5.8-10.4a3.292,3.292,0,0,1,1.9.6.54.54,0,0,1,.2.8,15.515,15.515,0,0,0-1.3,6.4c0,4,1.3,7.6,3.3,8.7a.54.54,0,0,1,.2.8C17.1,321.9,15.6,323.1,13.9,323.1Zm0-19.5c-2.5,0-4.5,4.2-4.5,9.1s2.1,9.1,4.5,9.1c1.1,0,2.1-.8,3-2.3-2-1.7-3.4-5.4-3.4-9.5a15.73,15.73,0,0,1,1.2-6.3A2.484,2.484,0,0,0,13.9,303.6Z" />
        <path d="M13.9,352.4c-3.3,0-5.8-4.6-5.8-10.4s2.6-10.4,5.8-10.4,5.8,4.6,5.8,10.4S17.1,352.4,13.9,352.4Zm0-19.6c-2.5,0-4.5,4.2-4.5,9.1s2.1,9.1,4.5,9.1,4.5-4.2,4.5-9.1S16.3,332.8,13.9,332.8Z" />
        <path d="M13.9,352.4c-3.3,0-5.8-4.6-5.8-10.4s2.6-10.4,5.8-10.4a3.292,3.292,0,0,1,1.9.6.54.54,0,0,1,.2.8,15.515,15.515,0,0,0-1.3,6.4c0,4,1.3,7.6,3.3,8.7a.54.54,0,0,1,.2.8C17.1,351.1,15.6,352.4,13.9,352.4Zm0-19.6c-2.5,0-4.5,4.2-4.5,9.1s2.1,9.1,4.5,9.1c1.1,0,2.1-.8,3-2.3-2-1.7-3.4-5.4-3.4-9.5a15.73,15.73,0,0,1,1.2-6.3C14.4,332.9,14.1,332.8,13.9,332.8Z" />
        <path d="M13.9,382.5c-3.3,0-5.8-4.6-5.8-10.4s2.6-10.4,5.8-10.4,5.8,4.6,5.8,10.4S17.1,382.5,13.9,382.5Zm0-19.5c-2.5,0-4.5,4.2-4.5,9.1s2.1,9.1,4.5,9.1,4.5-4.2,4.5-9.1S16.3,363,13.9,363Z" />
        <path d="M13.9,382.5c-3.3,0-5.8-4.6-5.8-10.4s2.6-10.4,5.8-10.4a3.292,3.292,0,0,1,1.9.6.54.54,0,0,1,.2.8,15.515,15.515,0,0,0-1.3,6.4c0,4,1.3,7.6,3.3,8.7a.54.54,0,0,1,.2.8C17.1,381.3,15.6,382.5,13.9,382.5Zm0-19.5c-2.5,0-4.5,4.2-4.5,9.1s2.1,9.1,4.5,9.1c1.1,0,2.1-.8,3-2.3-2-1.7-3.4-5.4-3.4-9.5a15.73,15.73,0,0,1,1.2-6.3A2.484,2.484,0,0,0,13.9,363Z" />
        <path d="M178.7,279.2a11,11,0,1,1,11-11A11.032,11.032,0,0,1,178.7,279.2Zm0-20.6a9.7,9.7,0,1,0,9.7,9.7A9.709,9.709,0,0,0,178.7,258.6Z" />
        <path d="M178.7,279.2a10.931,10.931,0,0,1-5.6-1.5.67.67,0,0,1-.3-.8.569.569,0,0,1,.7-.4c.3,0,.7.1,1,.1a9.709,9.709,0,0,0,9.7-9.7,9.534,9.534,0,0,0-4.8-8.3.67.67,0,0,1-.3-.8.569.569,0,0,1,.7-.4,10.929,10.929,0,0,1-1.1,21.8Zm-2.3-1.6a8.329,8.329,0,0,0,2.2.3,9.685,9.685,0,0,0,3.8-18.6,10.938,10.938,0,0,1,3,7.5A10.779,10.779,0,0,1,176.4,277.6Z" />
        <path d="M170.3,274.8a.55.55,0,0,1-.5-.3,13.575,13.575,0,0,1-1.3-2.4.63.63,0,0,1,.3-.8,9.808,9.808,0,0,0,5.7-8.8,8.424,8.424,0,0,0-.7-3.5.63.63,0,0,1,.3-.8,9.108,9.108,0,0,1,3.1-.9.713.713,0,0,1,.6.3,11.2,11.2,0,0,1,2,6.3,10.76,10.76,0,0,1-9.5,10.9Zm-.4-2.5a7.46,7.46,0,0,0,.7,1.2,9.746,9.746,0,0,0,8.1-9.5,9.177,9.177,0,0,0-1.6-5.3,12.09,12.09,0,0,0-1.8.5,9.671,9.671,0,0,1,.5,3.4A10.975,10.975,0,0,1,169.9,272.3Z" />
        <path d="M178.7,311.1a11,11,0,1,1,11-11A11.032,11.032,0,0,1,178.7,311.1Zm0-20.7a9.7,9.7,0,1,0,9.7,9.7A9.709,9.709,0,0,0,178.7,290.4Z" />
        <path d="M178.7,311.1a10.931,10.931,0,0,1-5.6-1.5.67.67,0,0,1-.3-.8.569.569,0,0,1,.7-.4c.3,0,.7.1,1,.1a9.709,9.709,0,0,0,9.7-9.7,9.534,9.534,0,0,0-4.8-8.3.67.67,0,0,1-.3-.8.569.569,0,0,1,.7-.4,10.929,10.929,0,0,1-1.1,21.8Zm-2.3-1.6a8.329,8.329,0,0,0,2.2.3,9.685,9.685,0,0,0,3.8-18.6,10.938,10.938,0,0,1,3,7.5A10.779,10.779,0,0,1,176.4,309.5Z" />
        <path d="M170.3,306.7a.55.55,0,0,1-.5-.3,13.575,13.575,0,0,1-1.3-2.4.63.63,0,0,1,.3-.8,9.808,9.808,0,0,0,5.7-8.8,8.424,8.424,0,0,0-.7-3.5.63.63,0,0,1,.3-.8,9.108,9.108,0,0,1,3.1-.9.713.713,0,0,1,.6.3,11.2,11.2,0,0,1,2,6.3,10.76,10.76,0,0,1-9.5,10.9Zm-.4-2.5a7.46,7.46,0,0,0,.7,1.2,9.746,9.746,0,0,0,8.1-9.5,9.177,9.177,0,0,0-1.6-5.3,12.09,12.09,0,0,0-1.8.5,9.671,9.671,0,0,1,.5,3.4A11.092,11.092,0,0,1,169.9,304.2Z" />
        <path d="M178.7,342.8a11,11,0,1,1,11-11A11.164,11.164,0,0,1,178.7,342.8Zm0-20.7a9.7,9.7,0,1,0,9.7,9.7A9.709,9.709,0,0,0,178.7,322.1Z" />
        <path d="M178.7,342.8a10.931,10.931,0,0,1-5.6-1.5.67.67,0,0,1-.3-.8.569.569,0,0,1,.7-.4c.3,0,.7.1,1,.1a9.709,9.709,0,0,0,9.7-9.7,9.534,9.534,0,0,0-4.8-8.3.67.67,0,0,1-.3-.8.569.569,0,0,1,.7-.4,10.929,10.929,0,0,1-1.1,21.8Zm-2.3-1.6a8.329,8.329,0,0,0,2.2.3,9.685,9.685,0,0,0,3.8-18.6,10.938,10.938,0,0,1,3,7.5A10.779,10.779,0,0,1,176.4,341.2Z" />
        <path d="M170.3,338.4a.55.55,0,0,1-.5-.3,13.575,13.575,0,0,1-1.3-2.4.63.63,0,0,1,.3-.8,9.808,9.808,0,0,0,5.7-8.8,8.424,8.424,0,0,0-.7-3.5.63.63,0,0,1,.3-.8,9.108,9.108,0,0,1,3.1-.9.713.713,0,0,1,.6.3,11.2,11.2,0,0,1,2,6.3,10.831,10.831,0,0,1-9.5,10.9Zm-.4-2.5a7.46,7.46,0,0,0,.7,1.2,9.746,9.746,0,0,0,8.1-9.5,9.177,9.177,0,0,0-1.6-5.3,12.09,12.09,0,0,0-1.8.5,9.671,9.671,0,0,1,.5,3.4A11.092,11.092,0,0,1,169.9,335.9Z" />
        <path d="M178.7,374.4a11,11,0,1,1,11-11A11.032,11.032,0,0,1,178.7,374.4Zm0-20.6a9.7,9.7,0,1,0,9.7,9.7A9.709,9.709,0,0,0,178.7,353.8Z" />
        <path d="M178.7,374.4a10.931,10.931,0,0,1-5.6-1.5.67.67,0,0,1-.3-.8.569.569,0,0,1,.7-.4c.3,0,.7.1,1,.1a9.709,9.709,0,0,0,9.7-9.7,9.534,9.534,0,0,0-4.8-8.3.67.67,0,0,1-.3-.8.569.569,0,0,1,.7-.4,10.929,10.929,0,0,1-1.1,21.8Zm-2.3-1.5a8.329,8.329,0,0,0,2.2.3,9.685,9.685,0,0,0,3.8-18.6,10.938,10.938,0,0,1,3,7.5A10.779,10.779,0,0,1,176.4,372.9Z" />
        <path d="M170.3,370.1a.55.55,0,0,1-.5-.3,13.575,13.575,0,0,1-1.3-2.4.63.63,0,0,1,.3-.8,9.808,9.808,0,0,0,5.7-8.8,8.424,8.424,0,0,0-.7-3.5.63.63,0,0,1,.3-.8,9.108,9.108,0,0,1,3.1-.9.713.713,0,0,1,.6.3,11.2,11.2,0,0,1,2,6.3,10.831,10.831,0,0,1-9.5,10.9Zm-.4-2.6a7.46,7.46,0,0,0,.7,1.2,9.746,9.746,0,0,0,8.1-9.5,9.177,9.177,0,0,0-1.6-5.3,12.09,12.09,0,0,0-1.8.5,9.671,9.671,0,0,1,.5,3.4A10.975,10.975,0,0,1,169.9,367.5Z" />
        <path d="M178.7,406.5a11,11,0,1,1,11-11A11.032,11.032,0,0,1,178.7,406.5Zm0-20.6a9.7,9.7,0,1,0,9.7,9.7A9.709,9.709,0,0,0,178.7,385.9Z" />
        <path d="M178.7,406.5a10.931,10.931,0,0,1-5.6-1.5.67.67,0,0,1-.3-.8.569.569,0,0,1,.7-.4c.3,0,.7.1,1,.1a9.709,9.709,0,0,0,9.7-9.7,9.534,9.534,0,0,0-4.8-8.3.67.67,0,0,1-.3-.8.569.569,0,0,1,.7-.4,10.929,10.929,0,0,1-1.1,21.8Zm-2.3-1.5a8.329,8.329,0,0,0,2.2.3,9.685,9.685,0,0,0,3.8-18.6,10.938,10.938,0,0,1,3,7.5A10.779,10.779,0,0,1,176.4,405Z" />
        <path d="M170.3,402.2a.55.55,0,0,1-.5-.3,13.575,13.575,0,0,1-1.3-2.4.63.63,0,0,1,.3-.8,9.808,9.808,0,0,0,5.7-8.8,8.424,8.424,0,0,0-.7-3.5.63.63,0,0,1,.3-.8,9.108,9.108,0,0,1,3.1-.9.713.713,0,0,1,.6.3,11.2,11.2,0,0,1,2,6.3,10.831,10.831,0,0,1-9.5,10.9Zm-.4-2.6a7.46,7.46,0,0,0,.7,1.2,9.746,9.746,0,0,0,8.1-9.5,9.177,9.177,0,0,0-1.6-5.3,12.09,12.09,0,0,0-1.8.5,9.671,9.671,0,0,1,.5,3.4A10.975,10.975,0,0,1,169.9,399.6Z" />
        <path d="M111.8,278.6a11,11,0,1,1,11-11A11.032,11.032,0,0,1,111.8,278.6Zm0-20.6a9.7,9.7,0,1,0,9.7,9.7A9.709,9.709,0,0,0,111.8,258Z" />
        <path d="M111.9,278.7A11.237,11.237,0,0,1,106,277a10.661,10.661,0,0,1-4.8-6.9,11.015,11.015,0,0,1,7.2-12.8.67.67,0,0,1,.8.3.605.605,0,0,1-.1.8,9.8,9.8,0,0,0-2.9,9.2,9.7,9.7,0,0,0,11.5,7.4c.3-.1.7-.2,1-.3a.67.67,0,0,1,.8.3.605.605,0,0,1-.1.8,10.977,10.977,0,0,1-5.1,2.7A19.418,19.418,0,0,1,111.9,278.7Zm-5.7-18.9a9.6,9.6,0,0,0-3.8,10,10.182,10.182,0,0,0,4.2,6.1,9.444,9.444,0,0,0,7.3,1.3,17.232,17.232,0,0,0,2.1-.7,11.051,11.051,0,0,1-11.2-8.6A11.971,11.971,0,0,1,106.2,259.8Z" />
        <path d="M119.6,274.1a10.983,10.983,0,0,1-9.1-17.1.55.55,0,0,1,.5-.3,11.983,11.983,0,0,1,2.7.1c.2,0,.3.1.4.3a1.087,1.087,0,0,1,.1.5,9.245,9.245,0,0,0-.3,5.2h0a9.621,9.621,0,0,0,7.7,7.4c.2,0,.3.1.4.3a1.087,1.087,0,0,1,.1.5,9.65,9.65,0,0,1-1.2,2.5.55.55,0,0,1-.5.3A.829.829,0,0,1,119.6,274.1ZM111.4,258a9.626,9.626,0,0,0,8.5,14.8c.2-.4.5-.8.7-1.3a10.927,10.927,0,0,1-8-8.3h0a11.423,11.423,0,0,1,.1-5.2Z" />
        <path d="M111.8,310.6a11,11,0,1,1,11-11A11.032,11.032,0,0,1,111.8,310.6Zm0-20.6a9.7,9.7,0,1,0,9.7,9.7A9.709,9.709,0,0,0,111.8,290Z" />
        <path d="M111.9,310.7a10.991,10.991,0,0,1-3.5-21.4.67.67,0,0,1,.8.3.605.605,0,0,1-.1.8,9.8,9.8,0,0,0-2.9,9.2,9.7,9.7,0,0,0,11.5,7.4c.3-.1.7-.2,1-.3a.67.67,0,0,1,.8.3.605.605,0,0,1-.1.8,10.977,10.977,0,0,1-5.1,2.7A19.418,19.418,0,0,1,111.9,310.7Zm-5.7-18.9a9.6,9.6,0,0,0-3.8,10,9.7,9.7,0,0,0,11.5,7.4,17.232,17.232,0,0,0,2.1-.7,11.051,11.051,0,0,1-11.2-8.6A11.971,11.971,0,0,1,106.2,291.8Z" />
        <path d="M119.6,306.1a10.983,10.983,0,0,1-9.1-17.1.55.55,0,0,1,.5-.3h.8a12.252,12.252,0,0,1,1.9.2c.2,0,.3.1.4.3a1.087,1.087,0,0,1,.1.5,9.245,9.245,0,0,0-.3,5.2,9.621,9.621,0,0,0,7.7,7.4c.2,0,.3.1.4.3a1.087,1.087,0,0,1,.1.5,9.65,9.65,0,0,1-1.2,2.5.55.55,0,0,1-.5.3A1.077,1.077,0,0,1,119.6,306.1ZM111.4,290a9.626,9.626,0,0,0,8.5,14.8c.2-.4.5-.8.7-1.3a10.927,10.927,0,0,1-8-8.3h0a11.423,11.423,0,0,1,.1-5.2Z" />
        <path d="M111.8,342.7a11,11,0,1,1,11-11A11.032,11.032,0,0,1,111.8,342.7Zm0-20.6a9.7,9.7,0,1,0,9.7,9.7A9.709,9.709,0,0,0,111.8,322.1Z" />
        <path d="M111.9,342.7a10.991,10.991,0,0,1-3.5-21.4.67.67,0,0,1,.8.3.605.605,0,0,1-.1.8,9.8,9.8,0,0,0-2.9,9.2,9.7,9.7,0,0,0,11.5,7.4c.3-.1.7-.2,1-.3a.67.67,0,0,1,.8.3.605.605,0,0,1-.1.8,10.977,10.977,0,0,1-5.1,2.7A9.737,9.737,0,0,1,111.9,342.7Zm-5.7-18.8a9.6,9.6,0,0,0-3.8,10,9.7,9.7,0,0,0,11.5,7.4,17.232,17.232,0,0,0,2.1-.7,11.051,11.051,0,0,1-11.2-8.6A11.871,11.871,0,0,1,106.2,323.9Z" />
        <path d="M119.6,338.2a10.983,10.983,0,0,1-9.1-17.1.55.55,0,0,1,.5-.3h.8a12.252,12.252,0,0,1,1.9.2c.2,0,.3.1.4.3a1.087,1.087,0,0,1,.1.5,9.245,9.245,0,0,0-.3,5.2,9.621,9.621,0,0,0,7.7,7.4c.2,0,.3.1.4.3a1.087,1.087,0,0,1,.1.5,9.65,9.65,0,0,1-1.2,2.5.55.55,0,0,1-.5.3A1.077,1.077,0,0,1,119.6,338.2Zm-8.2-16.1a9.626,9.626,0,0,0,8.5,14.8c.2-.4.5-.8.7-1.3a10.927,10.927,0,0,1-8-8.3h0a11.423,11.423,0,0,1,.1-5.2Z" />
        <path d="M111.8,374.8a11,11,0,1,1,11-11A11.032,11.032,0,0,1,111.8,374.8Zm0-20.7a9.7,9.7,0,1,0,9.7,9.7A9.709,9.709,0,0,0,111.8,354.1Z" />
        <path d="M111.9,374.8a10.991,10.991,0,0,1-3.5-21.4.67.67,0,0,1,.8.3.605.605,0,0,1-.1.8,9.8,9.8,0,0,0-2.9,9.2,9.7,9.7,0,0,0,11.5,7.4c.3-.1.7-.2,1-.3a.67.67,0,0,1,.8.3.605.605,0,0,1-.1.8,10.977,10.977,0,0,1-5.1,2.7A19.412,19.412,0,0,1,111.9,374.8ZM106.2,356a9.6,9.6,0,0,0-3.8,10,9.7,9.7,0,0,0,11.5,7.4,17.232,17.232,0,0,0,2.1-.7,11.051,11.051,0,0,1-11.2-8.6A11.771,11.771,0,0,1,106.2,356Z" />
        <path d="M119.6,370.2a10.983,10.983,0,0,1-9.1-17.1.55.55,0,0,1,.5-.3h.8a12.252,12.252,0,0,1,1.9.2c.2,0,.3.1.4.3a1.087,1.087,0,0,1,.1.5,9.245,9.245,0,0,0-.3,5.2h0a9.621,9.621,0,0,0,7.7,7.4c.2,0,.3.1.4.3a1.087,1.087,0,0,1,.1.5,9.65,9.65,0,0,1-1.2,2.5.55.55,0,0,1-.5.3A1.077,1.077,0,0,1,119.6,370.2Zm-8.2-16.1a9.626,9.626,0,0,0,8.5,14.8c.2-.4.5-.8.7-1.3a10.927,10.927,0,0,1-8-8.3h0a11.423,11.423,0,0,1,.1-5.2Z" />
        <path d="M111.8,405.9a11,11,0,1,1,11-11A11.032,11.032,0,0,1,111.8,405.9Zm0-20.6a9.7,9.7,0,1,0,9.7,9.7A9.709,9.709,0,0,0,111.8,385.3Z" />
        <path d="M111.9,405.9a10.991,10.991,0,0,1-3.5-21.4.67.67,0,0,1,.8.3.605.605,0,0,1-.1.8,9.8,9.8,0,0,0-2.9,9.2,9.7,9.7,0,0,0,11.5,7.4c.3-.1.7-.2,1-.3a.67.67,0,0,1,.8.3.605.605,0,0,1-.1.8,10.977,10.977,0,0,1-5.1,2.7A19.418,19.418,0,0,1,111.9,405.9Zm-5.7-18.8a9.6,9.6,0,0,0-3.8,10,9.7,9.7,0,0,0,11.5,7.4,17.232,17.232,0,0,0,2.1-.7,11.051,11.051,0,0,1-11.2-8.6A11.871,11.871,0,0,1,106.2,387.1Z" />
        <path d="M119.6,401.4a10.983,10.983,0,0,1-9.1-17.1.55.55,0,0,1,.5-.3h.8a12.252,12.252,0,0,1,1.9.2c.2,0,.3.1.4.3a1.087,1.087,0,0,1,.1.5,9.245,9.245,0,0,0-.3,5.2h0a9.621,9.621,0,0,0,7.7,7.4c.2,0,.3.1.4.3a1.087,1.087,0,0,1,.1.5,9.65,9.65,0,0,1-1.2,2.5.55.55,0,0,1-.5.3A1.077,1.077,0,0,1,119.6,401.4Zm-8.2-16.1a9.626,9.626,0,0,0,8.5,14.8c.2-.4.5-.8.7-1.3a10.927,10.927,0,0,1-8-8.3h0a11.423,11.423,0,0,1,.1-5.2Z" />
        <path d="M225.3,275.5c-5.3,0-9.5-4.8-9.5-10.7s4.3-10.7,9.5-10.7c5.3,0,9.5,4.8,9.5,10.7S230.6,275.5,225.3,275.5Zm0-20.2c-4.5,0-8.2,4.2-8.2,9.4s3.7,9.4,8.2,9.4,8.2-4.2,8.2-9.4S229.9,255.3,225.3,255.3Z" />
        <path d="M225.3,275.5a9.006,9.006,0,0,1-6.7-3.1.755.755,0,0,1-.1-.8.778.778,0,0,1,.8-.2,6.723,6.723,0,0,0,2.4.4c4.5,0,8.2-4.2,8.2-9.4a10.171,10.171,0,0,0-2.5-6.8.755.755,0,0,1-.1-.8.663.663,0,0,1,.7-.2,10.587,10.587,0,0,1,6.7,10.2C234.8,270.7,230.6,275.5,225.3,275.5Zm-3.9-2.5a6.8,6.8,0,0,0,3.9,1.1c4.5,0,8.2-4.2,8.2-9.4A9.875,9.875,0,0,0,230,257a11.857,11.857,0,0,1,1.3,5.3C231.3,268.4,226.7,273.3,221.4,273Z" />
        <path d="M217.3,269.8a.675.675,0,0,1-.6-.4,15.912,15.912,0,0,1-.9-3.3.755.755,0,0,1,.4-.7c3.2-1.4,5.2-4.9,5.2-8.8a4.868,4.868,0,0,0-.1-1.2.755.755,0,0,1,.4-.7,7.349,7.349,0,0,1,3-.7.548.548,0,0,1,.6.4,12.272,12.272,0,0,1,1,4.6c0,5.8-3.9,10.5-9,10.8Zm-.1-3.4a11.7,11.7,0,0,0,.5,2c4.1-.5,7.3-4.5,7.3-9.4a9.366,9.366,0,0,0-.7-3.7,7.492,7.492,0,0,0-1.6.4v.9A10.534,10.534,0,0,1,217.2,266.4Z" />
        <path d="M225.3,307.4c-5.3,0-9.5-4.8-9.5-10.7s4.3-10.7,9.5-10.7c5.3,0,9.5,4.8,9.5,10.7C234.8,302.5,230.6,307.4,225.3,307.4Zm0-20.2c-4.5,0-8.2,4.2-8.2,9.4s3.7,9.4,8.2,9.4,8.2-4.2,8.2-9.4C233.6,291.4,229.9,287.2,225.3,287.2Z" />
        <path d="M225.3,307.4a9.006,9.006,0,0,1-6.7-3.1.755.755,0,0,1-.1-.8.778.778,0,0,1,.8-.2,6.723,6.723,0,0,0,2.4.4c4.5,0,8.2-4.2,8.2-9.4a10.171,10.171,0,0,0-2.5-6.8.755.755,0,0,1-.1-.8.778.778,0,0,1,.8-.2,10.587,10.587,0,0,1,6.7,10.2C234.8,302.5,230.6,307.4,225.3,307.4Zm-3.9-2.5a6.894,6.894,0,0,0,3.9,1.2c4.5,0,8.2-4.2,8.2-9.4A9.875,9.875,0,0,0,230,289a11.857,11.857,0,0,1,1.3,5.3C231.3,300.3,226.7,305.2,221.4,304.9Z" />
        <path d="M217.3,301.7a.675.675,0,0,1-.6-.4,15.912,15.912,0,0,1-.9-3.3.755.755,0,0,1,.4-.7c3.2-1.4,5.2-4.9,5.2-8.8a4.869,4.869,0,0,0-.1-1.2.755.755,0,0,1,.4-.7,7.349,7.349,0,0,1,3-.7.548.548,0,0,1,.6.4,12.272,12.272,0,0,1,1,4.6c0,5.8-3.9,10.4-9,10.8Zm-.1-3.4a11.7,11.7,0,0,0,.5,2c4.1-.5,7.3-4.5,7.3-9.4a9.366,9.366,0,0,0-.7-3.7,7.493,7.493,0,0,0-1.6.4v.9A10.534,10.534,0,0,1,217.2,298.3Z" />
        <path d="M225.3,339.4c-5.3,0-9.5-4.8-9.5-10.7s4.3-10.7,9.5-10.7c5.3,0,9.5,4.8,9.5,10.7S230.6,339.4,225.3,339.4Zm0-20.2c-4.5,0-8.2,4.2-8.2,9.4s3.7,9.4,8.2,9.4,8.2-4.2,8.2-9.4C233.6,323.5,229.9,319.2,225.3,319.2Z" />
        <path d="M225.3,339.4a9.006,9.006,0,0,1-6.7-3.1.755.755,0,0,1-.1-.8.778.778,0,0,1,.8-.2,6.723,6.723,0,0,0,2.4.4c4.5,0,8.2-4.2,8.2-9.4a10.171,10.171,0,0,0-2.5-6.8.755.755,0,0,1-.1-.8.778.778,0,0,1,.8-.2,10.587,10.587,0,0,1,6.7,10.2C234.8,334.6,230.6,339.4,225.3,339.4Zm-3.9-2.5a6.894,6.894,0,0,0,3.9,1.2c4.5,0,8.2-4.2,8.2-9.4A9.875,9.875,0,0,0,230,321a11.857,11.857,0,0,1,1.3,5.3C231.3,332.3,226.7,337.2,221.4,336.9Z" />
        <path d="M217.3,333.7a.675.675,0,0,1-.6-.4,15.912,15.912,0,0,1-.9-3.3.755.755,0,0,1,.4-.7c3.2-1.4,5.2-4.9,5.2-8.8a4.869,4.869,0,0,0-.1-1.2.755.755,0,0,1,.4-.7,7.349,7.349,0,0,1,3-.7.548.548,0,0,1,.6.4,12.272,12.272,0,0,1,1,4.6c0,5.8-3.9,10.5-9,10.8Zm-.1-3.4a11.7,11.7,0,0,0,.5,2c4.1-.5,7.3-4.5,7.3-9.4a9.366,9.366,0,0,0-.7-3.7,7.493,7.493,0,0,0-1.6.4v.9A10.534,10.534,0,0,1,217.2,330.3Z" />
        <path d="M225.3,370.2c-5.3,0-9.5-4.8-9.5-10.7s4.3-10.7,9.5-10.7c5.3,0,9.5,4.8,9.5,10.7S230.6,370.2,225.3,370.2Zm0-20.1c-4.5,0-8.2,4.2-8.2,9.4s3.7,9.4,8.2,9.4,8.2-4.2,8.2-9.4C233.6,354.3,229.9,350.1,225.3,350.1Z" />
        <path d="M225.3,370.2a9.006,9.006,0,0,1-6.7-3.1.755.755,0,0,1-.1-.8.778.778,0,0,1,.8-.2,6.723,6.723,0,0,0,2.4.4c4.5,0,8.2-4.2,8.2-9.4a10.171,10.171,0,0,0-2.5-6.8.755.755,0,0,1-.1-.8.663.663,0,0,1,.7-.2,10.587,10.587,0,0,1,6.7,10.2C234.8,365.4,230.6,370.2,225.3,370.2Zm-3.9-2.4a6.8,6.8,0,0,0,3.9,1.1c4.5,0,8.2-4.2,8.2-9.4a9.875,9.875,0,0,0-3.5-7.7,11.857,11.857,0,0,1,1.3,5.3C231.3,363.1,226.7,368,221.4,367.8Z" />
        <path d="M217.3,364.5a.675.675,0,0,1-.6-.4,15.912,15.912,0,0,1-.9-3.3.755.755,0,0,1,.4-.7c3.2-1.4,5.2-4.9,5.2-8.8a4.869,4.869,0,0,0-.1-1.2.755.755,0,0,1,.4-.7,7.349,7.349,0,0,1,3-.7.548.548,0,0,1,.6.4,12.272,12.272,0,0,1,1,4.6c0,5.8-3.9,10.5-9,10.8Zm-.1-3.4a11.7,11.7,0,0,0,.5,2c4.1-.5,7.3-4.5,7.3-9.4a9.366,9.366,0,0,0-.7-3.7,7.493,7.493,0,0,0-1.6.4v.9A10.534,10.534,0,0,1,217.2,361.1Z" />
        <path d="M225.3,401.8c-5.3,0-9.5-4.8-9.5-10.7s4.3-10.7,9.5-10.7c5.3,0,9.5,4.8,9.5,10.7S230.6,401.8,225.3,401.8Zm0-20.1c-4.5,0-8.2,4.2-8.2,9.4s3.7,9.4,8.2,9.4,8.2-4.2,8.2-9.4C233.6,385.9,229.9,381.7,225.3,381.7Z" />
        <path d="M225.3,401.8a9.006,9.006,0,0,1-6.7-3.1.755.755,0,0,1-.1-.8.778.778,0,0,1,.8-.2,6.723,6.723,0,0,0,2.4.4c4.5,0,8.2-4.2,8.2-9.4a10.171,10.171,0,0,0-2.5-6.8.755.755,0,0,1-.1-.8.663.663,0,0,1,.7-.2,10.587,10.587,0,0,1,6.7,10.2C234.8,397,230.6,401.8,225.3,401.8Zm-3.9-2.4a6.8,6.8,0,0,0,3.9,1.1c4.5,0,8.2-4.2,8.2-9.4a9.875,9.875,0,0,0-3.5-7.7,11.857,11.857,0,0,1,1.3,5.3C231.3,394.7,226.7,399.6,221.4,399.4Z" />
        <path d="M217.3,396.1a.675.675,0,0,1-.6-.4,15.912,15.912,0,0,1-.9-3.3.755.755,0,0,1,.4-.7c3.2-1.4,5.2-4.9,5.2-8.8a4.869,4.869,0,0,0-.1-1.2.755.755,0,0,1,.4-.7,7.349,7.349,0,0,1,3-.7.548.548,0,0,1,.6.4,12.272,12.272,0,0,1,1,4.6c0,5.8-3.9,10.5-9,10.8Zm-.1-3.3a11.7,11.7,0,0,0,.5,2c4.1-.5,7.3-4.5,7.3-9.4a9.366,9.366,0,0,0-.7-3.7,7.493,7.493,0,0,0-1.6.4v.9A10.639,10.639,0,0,1,217.2,392.8Z" />
        <path d="M275.4,267c-3.8,0-6.7-4.7-6.7-10.7s2.9-10.7,6.7-10.7,6.7,4.7,6.7,10.7S279.2,267,275.4,267Zm0-20.1c-2.9,0-5.4,4.3-5.4,9.4s2.5,9.4,5.4,9.4,5.4-4.3,5.4-9.4S278.4,246.9,275.4,246.9Z" />
        <path d="M275.4,267a5.508,5.508,0,0,1-4.4-2.6.661.661,0,0,1-.1-.6.781.781,0,0,1,.4-.4c2.5-.9,4.3-4.8,4.3-9.2a12.819,12.819,0,0,0-2-7.3.661.661,0,0,1-.1-.6.781.781,0,0,1,.4-.4,4.013,4.013,0,0,1,1.5-.3c3.8,0,6.7,4.7,6.7,10.7S279.2,267,275.4,267Zm-2.8-2.8a3.954,3.954,0,0,0,2.9,1.5c2.9,0,5.4-4.3,5.4-9.4,0-5.2-2.6-9.6-5.6-9.4a15.05,15.05,0,0,1,1.8,7.2C277,258.8,275.2,262.7,272.6,264.2Z" />
        <path d="M275.4,297.8c-3.8,0-6.7-4.7-6.7-10.7s2.9-10.7,6.7-10.7,6.7,4.7,6.7,10.7S279.2,297.8,275.4,297.8Zm0-20c-2.9,0-5.4,4.3-5.4,9.4s2.5,9.4,5.4,9.4,5.4-4.3,5.4-9.4S278.4,277.8,275.4,277.8Z" />
        <path d="M275.4,297.8a5.508,5.508,0,0,1-4.4-2.6.661.661,0,0,1-.1-.6.781.781,0,0,1,.4-.4c2.5-.9,4.3-4.8,4.3-9.2a12.819,12.819,0,0,0-2-7.3.661.661,0,0,1-.1-.6.781.781,0,0,1,.4-.4,4.012,4.012,0,0,1,1.5-.3c3.8,0,6.7,4.7,6.7,10.7S279.2,297.8,275.4,297.8Zm-2.8-2.8a3.954,3.954,0,0,0,2.9,1.5c2.9,0,5.4-4.3,5.4-9.4,0-5.2-2.6-9.6-5.6-9.4a15.05,15.05,0,0,1,1.8,7.2C277,289.6,275.2,293.5,272.6,295Z" />
        <path d="M275.4,328.6c-3.8,0-6.7-4.7-6.7-10.7s2.9-10.7,6.7-10.7,6.7,4.7,6.7,10.7C282.1,324,279.2,328.6,275.4,328.6Zm0-20c-2.9,0-5.4,4.3-5.4,9.4s2.5,9.4,5.4,9.4,5.4-4.3,5.4-9.4S278.4,308.6,275.4,308.6Z" />
        <path d="M275.4,328.6A5.508,5.508,0,0,1,271,326a.661.661,0,0,1-.1-.6.781.781,0,0,1,.4-.4c2.5-.9,4.3-4.8,4.3-9.2a12.819,12.819,0,0,0-2-7.3.661.661,0,0,1-.1-.6.781.781,0,0,1,.4-.4,4.012,4.012,0,0,1,1.5-.3c3.8,0,6.7,4.7,6.7,10.7C282.1,324,279.2,328.6,275.4,328.6Zm-2.8-2.7a3.954,3.954,0,0,0,2.9,1.5c2.9,0,5.4-4.3,5.4-9.4,0-5.2-2.6-9.6-5.6-9.4a15.05,15.05,0,0,1,1.8,7.2C277,320.4,275.2,324.4,272.6,325.9Z" />
        <path d="M275.4,358.9c-3.8,0-6.7-4.7-6.7-10.7s2.9-10.7,6.7-10.7,6.7,4.7,6.7,10.7S279.2,358.9,275.4,358.9Zm0-20c-2.9,0-5.4,4.3-5.4,9.4s2.5,9.4,5.4,9.4,5.4-4.3,5.4-9.4S278.4,338.9,275.4,338.9Z" />
        <path d="M275.4,358.9a5.508,5.508,0,0,1-4.4-2.6.661.661,0,0,1-.1-.6.781.781,0,0,1,.4-.4c2.5-.9,4.3-4.8,4.3-9.2a12.819,12.819,0,0,0-2-7.3.661.661,0,0,1-.1-.6.781.781,0,0,1,.4-.4,4.012,4.012,0,0,1,1.5-.3c3.8,0,6.7,4.7,6.7,10.7S279.2,358.9,275.4,358.9Zm-2.8-2.8a3.954,3.954,0,0,0,2.9,1.5c2.9,0,5.4-4.3,5.4-9.4,0-5.2-2.6-9.6-5.6-9.4a15.05,15.05,0,0,1,1.8,7.2C277,350.7,275.2,354.6,272.6,356.1Z" />
        <path d="M275.4,390c-3.8,0-6.7-4.7-6.7-10.7s2.9-10.7,6.7-10.7,6.7,4.7,6.7,10.7S279.2,390,275.4,390Zm0-20c-2.9,0-5.4,4.3-5.4,9.4s2.5,9.4,5.4,9.4,5.4-4.3,5.4-9.4S278.4,370,275.4,370Z" />
        <path d="M275.4,390a5.508,5.508,0,0,1-4.4-2.6.661.661,0,0,1-.1-.6.781.781,0,0,1,.4-.4c2.5-.9,4.3-4.8,4.3-9.2a12.819,12.819,0,0,0-2-7.3.661.661,0,0,1-.1-.6.781.781,0,0,1,.4-.4,4.012,4.012,0,0,1,1.5-.3c3.8,0,6.7,4.7,6.7,10.7S279.2,390,275.4,390Zm-2.8-2.8a3.954,3.954,0,0,0,2.9,1.5c2.9,0,5.4-4.3,5.4-9.4,0-5.2-2.6-9.6-5.6-9.4a15.05,15.05,0,0,1,1.8,7.2C277,381.8,275.2,385.8,272.6,387.2Z" />
        <path d="M298.3,253.6c-2.6,0-2.7-10.2-2.7-10.6s0-10.6,2.7-10.6c2.6,0,2.7,10.2,2.7,10.6C300.9,243.4,300.9,253.6,298.3,253.6Zm-.1-20.1c-.5.3-1.5,3.6-1.5,9.5,0,5.8,1,9.2,1.6,9.5.5-.3,1.5-3.6,1.5-9.5S298.8,233.7,298.2,233.5Z" />
        <path d="M298.3,284.3c-2.6,0-2.7-10.2-2.7-10.6s0-10.6,2.7-10.6c2.6,0,2.7,10.2,2.7,10.6C300.9,274.1,300.9,284.3,298.3,284.3Zm-.1-20.1c-.5.3-1.5,3.6-1.5,9.5,0,5.8,1,9.2,1.6,9.5.5-.3,1.5-3.6,1.5-9.5S298.8,264.5,298.2,264.2Z" />
        <path d="M298.3,315c-2.6,0-2.7-10.2-2.7-10.6s0-10.6,2.7-10.6c2.6,0,2.7,10.2,2.7,10.6C300.9,304.8,300.9,315,298.3,315Zm-.1-20.1c-.5.3-1.5,3.6-1.5,9.5,0,5.8,1,9.2,1.6,9.5.5-.3,1.5-3.6,1.5-9.5C299.8,298.6,298.8,295.2,298.2,294.9Z" />
        <path d="M298.3,345.7c-2.6,0-2.7-10.2-2.7-10.6s0-10.6,2.7-10.6c2.6,0,2.7,10.2,2.7,10.6C300.9,335.5,300.9,345.7,298.3,345.7Zm-.1-20.1c-.5.3-1.5,3.6-1.5,9.5s1,9.2,1.6,9.5c.5-.3,1.5-3.6,1.5-9.5S298.8,325.9,298.2,325.6Z" />
        <path d="M298.3,376.4c-2.6,0-2.7-10.2-2.7-10.6s0-10.6,2.7-10.6c2.6,0,2.7,10.2,2.7,10.6S300.9,376.4,298.3,376.4Zm-.1-20.1c-.5.3-1.5,3.6-1.5,9.5s1,9.2,1.6,9.5c.5-.3,1.5-3.6,1.5-9.5S298.8,356.6,298.2,356.3Z" />
        <path d="M277.3,93.1a.576.576,0,0,1-.6-.6V84a.576.576,0,0,1,.6-.6.645.645,0,0,1,.6.6v8.4A.563.563,0,0,1,277.3,93.1Z" />
        <line x2="6.6" y2="3.3" transform="translate(119.5 65.1)" fill="none" />
        <path d="M126.1,69a.367.367,0,0,1-.3-.1l-6.7-3.3a.671.671,0,0,1,.6-1.2l6.7,3.3a.759.759,0,0,1,.3.9A.548.548,0,0,1,126.1,69Z" />
        <path d="M103.2,70a.576.576,0,0,1-.6-.6V66l1-.5,4,3.1a.64.64,0,0,1-.8,1l-2.9-2.3v2.1A.669.669,0,0,1,103.2,70Z" />
        <path d="M26.6,93.1a.576.576,0,0,1-.6-.6V84a.6.6,0,0,1,1.2,0v8.4A.669.669,0,0,1,26.6,93.1Z" />
      </g>
      <text transform="translate(694 4355)" fill="#1d1d1d">
        <tspan x="-22.008" y="0">
          35cm
        </tspan>
      </text>
      <text transform="translate(906 4578)" fill="#1d1d1d">
        <tspan x="-22.008" y="0">
          35cm
        </tspan>
      </text>
    </g>
  </svg>
);
